/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-twitch': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.543 9.5h1.793V5.125h-1.793V9.5zm-4.483 0h1.793V5.125H7.06V9.5zm8.966 1.144V2.5H3.473V13H7.06v2.584L9.355 13h4.286l2.385-2.356zm-2.773 4.981h-3.51L7.407 18.25h-2.14v-2.625H.782V3.795L1.95.75h15.87v10.651l-4.566 4.224z" _fill="#fff" fill-opacity=".4"/>'
  }
})
